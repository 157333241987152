import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import scrollTo from "gatsby-plugin-smoothscroll"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default () => {
  const { footer, contacts } = useSiteMetadata();
  // const { address, social } = useSiteMetadata()

  const date = new Date();
  const year = date.getFullYear();

  return (
    <footer>
      <div className="row">
        <div className="six columns info">

          <div className="footer-logo">
            <AnchorLink to="/">
              <img src={ footer.logo } alt="" />
            </AnchorLink>
          </div>

          <p>{ footer.text }</p>

        </div>
    
        <div className="six columns right-cols">
          <div className="row contact-us">

            <div className="contact">
              <FontAwesomeIcon icon={ faEnvelope } />
              <h3>Contact Us</h3>
              <ul>
                { contacts.map((contact, index) =>
                  <li><a href={ contact.url }>{ contact.text }</a></li>
                )}
              </ul>
            </div>

          </div>
        </div>

        <p className="copyright">&copy; {year} Rescue Paws</p>

        <div id="go-top">
          <button title="Back to Top" onClick={() => scrollTo('#top')}>
            <FontAwesomeIcon icon={ faChevronUp } />
          </button>
        </div>

      </div>
    </footer>
  );
}
