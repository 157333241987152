import React from "react"
import { Link } from "gatsby"
import content from "../../content/membership.yaml"

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

export default () => {

  library.add(fas)

  return (
    <section id="membership">
      <div className="row section-head">
        <h1>{ content.title }</h1>
        <p>{ content.body }</p>
      </div>

      <div className="row">
        <div className="pricing-tables bgrid-thirds s-bgrid-halves">
          { content.plans.map((plan, index) =>
            <div className="column">
              <div className="price-block">
                <h3 className="plan-title">
                  { plan.title }
                </h3>
                <p className="plan-price">
                  { plan.price }
                  <span className="plan-per">{ plan.per }</span>
                  <span className="plan-equivalent">(equivalent to { plan.equivalent })</span>
                </p>
                <footer className="plan-sign-up">
                  <button className="button" onClick={() => window.location = plan.signup.to}>
                    { plan.signup.label }
                  </button>
                </footer>
              </div>
            </div>
          )
          }
        </div>
      </div>
    </section>
  )
}
