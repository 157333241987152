import React, { Component } from 'react';

import content from '../../content/charities.yaml';
const charities = content.charities;

export default class Charities extends Component {
  render() {
    return (
      <section id="charities">

        <div className="row section-head">
          <h1>{ content.title }</h1>
          <p>{ content.body }</p>
        </div>

        <div className="buttons">
          <a 
            className="button contact-us" 
            href={ content.contactus.to }
          >
            { content.contactus.label }
          </a>
        </div>
            
        <div className="row">
          <div className="twelve columns">
            <div id="charities-wrapper" className="bgrid-quarters s-bgrid-thirds cf">

              {charities.map((charity, index) => {
                return (
                  <div className="columns item" key={index.toString()}>
                    <div className="item-wrap">

                      <a
                        href={charity.url}
                        data-imagelightbox="a"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={'images/charities/' + charity.image}
                          alt={charity.caption} 
                        />
                        <div className="overlay"/>
                      </a>
                    </div>
                  </div>
                )
              })}

            </div>
          </div>
        </div>
        <div>
      </div>
    </section>
    );
  }
}
