import React from "react";

import Layout from "../components/layout";

import Hero from "../components/hero";
import About from "../components/about";
import Charities from "../components/charities";
import Testimonials from "../components/testimonials";
import Membership from "../components/membership";

import { useSiteMetadata } from "../hooks/use-site-metadata";

export default () => {
  const { sections } = useSiteMetadata()
  const availableSections = {
    "hero": Hero,
    "about": About,
    "charities": Charities,
    "testimonials": Testimonials,
    "membership": Membership,    
  }

  return (
    <>
      <Layout>
        { sections.map(section => {
          let Tagname = availableSections[section];
          return <Tagname />
        }) }
      </Layout>
    </>
  )
}
