import React from "react"
import ScrollAnimation from 'react-animate-on-scroll'
import about from "../../content/about.yaml"

export default () => {

  const content = about.about;
  const title = about.title;
  return (
    <section id='about'>

      <div className="row section-head">
        <h1>{ title }</h1>
      </div>

      {content.map((value, index) => {

        // No image
        if (!value.image) return (
          <div className={ 'row feature ' + value.name }>
            <div className={ 'twelve columns left' }>
              <h3>{value.title}</h3>
              <p>{value.body}</p>
            </div>
          </div>
        );

        // Image
        const imagepos = value.imagePosition;
        const pos = (imagepos === "left") ? "right" : "left"
        const media = (<img src={"images/about/" + value.image} alt="" />);

        return (
          <div className={ 'row feature ' + value.name }>
            <div className={ 'six columns ' + pos }>
              <h3>{ value.title }</h3>
              <p>{ value.body }</p>
            </div>
            <ScrollAnimation
              animateIn="pulse"
              animateOnce={true}
              initiallyVisible={true}
              className={ 'six columns ' + imagepos }
              >
              { media }
            </ScrollAnimation>
          </div>
        )
      })}
    </section>
  )                 
};