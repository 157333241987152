import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSiteMetadata } from "../hooks/use-site-metadata"

import content from '../../content/header-buttons.yaml';
import NavMenu from "./navmenu";

const buttons = content.buttons;

export default () => (
  <header>
    <div className="logo">
      <AnchorLink to="/#top">
        <img alt="" src={ useSiteMetadata().logo } />
      </AnchorLink>
    </div>

    <NavMenu />

    <button className="sign-in"
      onClick={() => window.location = buttons.signin.url}
    >
      { buttons.signin.label }
    </button>

  </header>
);